<template>
  <component
    :is="itemGroup === undefined ? 'div' : 'b-card'"
    class="p-0"
    v-bind="itemGroup === undefined ? {} : { 'no-body': true }"
  >

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="itemGroup === undefined"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-users-list'}"
        >
          User List
        </b-link>
        for other users.
      </div>
    </b-alert>

    <b-card
      :title="itemGroup.item_group_name"
    >
      <item-group-items
        :item-group="itemGroup"
      />
    </b-card>
  </component>
</template>

<script>
import {
  BCard, BAlert, BLink,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import store from '@/store'
import itemStoreService from '@/services/itemStoreService'
import ItemGroupDetails from '@/views/ItemGroupDetails.vue'
import ItemGroupItems from '@/views/ItemGroupItems.vue'

export default {
  components: {
    BCard,
    BAlert,
    BLink,

    ItemGroupDetails,
    ItemGroupItems,
  },
  setup() {
    const itemGroup = ref(null)

    const ITEM_STORE_MODULE_NAME = 'app-item'
    if (!store.hasModule(ITEM_STORE_MODULE_NAME)) store.registerModule(ITEM_STORE_MODULE_NAME, itemStoreService)

    onUnmounted(() => {
      if (store.hasModule(ITEM_STORE_MODULE_NAME)) store.unregisterModule(ITEM_STORE_MODULE_NAME)
    })

    // TODO: replace with api call
    itemGroup.value = router.currentRoute.params

    return {
      itemGroup,
    }
  },
}
</script>

<style>

</style>
