<template>
  <div>
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
      >

        <!-- Media -->
        <b-media class="mb-2 align-items-center">
          <template #aside>
            <b-avatar
              ref="previewEl"
              :src="itemGroup.item_group_image_url"
              :text="avatarText(itemGroup.item_group_name)"
              variant="light-primary"
              size="90px"
              rounded
            />
          </template>
          <div class="d-flex flex-wrap">
            <b-button
              variant="primary"
              @click="$refs.refInputEl.click()"
            >
              <input
                ref="refInputEl"
                type="file"
                class="d-none"
                @input="inputImageRenderer"
              >
              <span class="d-none d-sm-inline">Update</span>
              <feather-icon
                icon="EditIcon"
                class="d-inline d-sm-none"
              />
            </b-button>
            <b-button
              v-if="itemGroup.item_group_image_url != null"
              variant="outline-secondary"
              class="ml-1"
            >
              <span class="d-none d-sm-inline">Remove</span>
              <feather-icon
                icon="TrashIcon"
                class="d-inline d-sm-none"
              />
            </b-button>
          </div>
        </b-media>

        <b-row>
          <!-- Item Group Name -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              name="Item Group Name"
              rules="required|max:120"
            >
              <b-form-group
                label="Item Group Name"
                label-for="item-group-name"
              >
                <b-form-input
                  id="item-group-name"
                  :value="itemGroup.item_group_name"
                  :state="getValidationState(validationContext)"
                  @input="handleInput(...arguments, 'item_group_name')"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Item Group Display Name -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              name="Item Group Display Name"
              rules="required|max:120"
            >
              <b-form-group
                label="Item Group Display Name"
                label-for="item-group-display-name"
              >
                <b-form-input
                  id="item-group-display-name"
                  :value="itemGroup.item_group_display_name"
                  :state="getValidationState(validationContext)"
                  @input="handleInput(...arguments, 'item_group_display_name')"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Item Group Price -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              name="Item Group Price"
              rules="required|min_value:0"
            >
              <b-form-group
                label="Item Group Price"
                label-for="item-group-price"
              >
                <b-input-group
                  id="item-group-price"
                >
                  <b-input-group-text slot="prepend">
                    $
                  </b-input-group-text>
                  <cleave
                    :value="itemGroup.item_group_price"
                    class="form-control"
                    :class="getValidationStateNonBootstrap(validationContext)"
                    :options="priceOptions"
                    @input="handleInput(...arguments, 'item_group_price')"
                  />
                </b-input-group>

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <div>
          <spinner-button
            :loading="false"
            variant="primary"
            type="submit"
            class="mr-2"
          >
            Save
          </spinner-button>
          <b-button
            variant="danger"
            @click="$refs.confirm.open()"
          >
            Delete
          </b-button>
        </div>
      </b-form>
    </validation-observer>
    <icon-modal
      ref="confirm"
      icon="XOctagonIcon"
      icon-classes="text-danger"
    >
      <p class="text-center">
        Are you sure you would like to delete this item group?
      </p>
      <template v-slot:footer>
        <b-button
          variant="default"
          @click="$refs.confirm.close()"
        >
          Go Back
        </b-button>
        <spinner-button
          variant="danger"
          :loading="deleting"
          @click="deleteItemGroup"
        >
          Delete
        </spinner-button>
      </template>
    </icon-modal>
  </div>
</template>

<script>
import {
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BInputGroup,
  BInputGroupText,
  BMedia,
  BAvatar,
  BButton,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  required, max, minValue,
} from '@validations'
import Cleave from 'vue-cleave-component'
import formValidation from '@core/comp-functions/forms/form-validation'
import SpinnerButton from '@/layouts/components/SpinnerButton.vue'
import { ref } from '@vue/composition-api'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { avatarText } from '@core/utils/filter'
import IconModal from '@/layouts/components/IconModal.vue'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import router from '@/router'

export default {
  components: {
    SpinnerButton,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BInputGroup,
    BInputGroupText,
    BMedia,
    BAvatar,
    BButton,
    Cleave,
    IconModal,

    ValidationProvider,
    ValidationObserver,
  },
  props: {
    itemGroup: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    required,
    max,
    minValue,
    priceOptions: {
      numeral: true,
      numeralPositiveOnly: true,
    },
  }),
  setup(props, { emit }) {
    const deleting = ref(false)
    const confirm = ref(null)

    const toast = useToast()

    const onSubmit = () => {
      console.log('hit')
    }

    const {
      refFormObserver, getValidationState, getValidationStateNonBootstrap,
    } = formValidation()

    const handleInput = (val, field) => {
      const clone = props.itemGroup
      clone[field] = val
      emit('update:itemGroup', clone)
    }

    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.itemGroup.item_group_image_url = base64
    })

    const deleteItemGroup = () => {
      deleting.value = true
      store.dispatch('app-item/deleteItemGroup', {
        id: props.itemGroup.item_group_id,
      }).then(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Successfully deleted the item group',
            icon: 'CheckSquareIcon',
            variant: 'success',
          },
        })

        router.push({
          name: 'item-groups',
        })
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error deleting item group. Please try again later',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    }

    return {
      refFormObserver,
      getValidationState,
      getValidationStateNonBootstrap,
      onSubmit,

      handleInput,

      refInputEl,
      previewEl,
      inputImageRenderer,
      avatarText,
      deleting,
      confirm,
      deleteItemGroup,
    }
  },
}
</script>
