<template>
  <div>
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
      >
        <b-row
          v-for="(item, i) in selected"
          :key="i"
        >
          <b-col
            cols="12"
            lg="4"
          >
            <validation-provider
              #default="validationContext"
              :name="`Item-${i}`"
              rules="required"
            >
              <b-form-group
                label="Item"
                class="mb-0"
                :label-for="`item-${i}`"
              >
                <v-select
                  v-model="selected[i].item_id"
                  :options="filterItems(i)"
                  label="item_name"
                  :reduce="val => val.item_id"
                  class="form-control p-0"
                  :class="[getValidationState(validationContext) == null ? 'border-0': '', getValidationStateNonBootstrap(validationContext)]"
                >
                  <template v-slot:option="data">
                    <p class="mb-0">
                      {{ data.item_name }}
                    </p>
                    <p class="mb-0 text-italic">
                      {{ data.item_display_name }}
                    </p>
                  </template>
                </v-select>
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            lg="4"
          >
            <validation-provider
              #default="validationContext"
              :name="`Item Quantity-${i}`"
              rules="required|min_value:1"
            >
              <b-form-group
                label="Item Quantity"
                :label-for="`quantity-${i}`"
                class="mb-0"
              >
                <b-form-input
                  :id="`quantity-${i}`"
                  v-model="selected[i].quantity"
                  action="number"
                  min="0"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            lg="2"
          >
            <div class="d-flex h-100">
              <b-button
                class="mt-auto"
                variant="danger"
                @click="confirmRemove(i)"
              >
                Remove
              </b-button>
            </div>
          </b-col>
          <b-col cols="12">
            <hr class="my-2">
          </b-col>
        </b-row>
        <div class="d-flex justify-content-between">
          <b-button
            v-if="selected.length < items.length"
            variant="primary"
            @click="addItem"
          >
            Add Item
          </b-button>

          <spinner-button
            variant="primary"
            :loading="saving"
            type="submit"
          >
            Save
          </spinner-button>
        </div>
      </b-form>
    </validation-observer>
    <icon-modal
      ref="confirm"
      icon="XOctagonIcon"
      icon-classes="text-danger"
      @close="itemAssnIndex = null"
    >
      <p class="text-center">
        Are you sure you would like to remove this item? Changes will not be applied until saved.
      </p>
      <template v-slot:footer>
        <b-button
          variant="default"
          @click="$refs.confirm.close()"
        >
          Go Back
        </b-button>
        <b-button
          variant="danger"
          @click="removeItem"
        >
          Remove
        </b-button>
      </template>
    </icon-modal>
  </div>
</template>

<script>
import {
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { ref, nextTick } from '@vue/composition-api'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required, minValue } from '@core/utils/validations/validations'
import SpinnerButton from '@/layouts/components/SpinnerButton.vue'
import IconModal from '@/layouts/components/IconModal.vue'

export default {
  components: {
    SpinnerButton,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BFormInvalidFeedback,
    vSelect,
    IconModal,

    ValidationProvider,
    ValidationObserver,
  },
  props: {
    itemGroup: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const items = ref([])
    const toast = useToast()
    const selected = ref([])
    const remove = ref([])
    const saving = ref(false)
    const itemAssnIndex = ref(null)
    const confirm = ref(null)

    store.dispatch('app-item/fetchItemsLite')
      .then(response => {
        items.value = response.data
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Something went wrong when retrieving the list of items. Please try again later',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })

    const fetch = () => {
      selected.value = []
      store.dispatch('app-item/fetchItemGroupAssns', {
        id: props.itemGroup.item_group_id,
      })
        .then(response => {
          if (response.data != null && Array.isArray(response.data)) {
            response.data.forEach(i => {
              selected.value.push({
                action: 'update',
                item_id: i.item.item_id,
                quantity: i.item_groups_items_assn_quantity,
              })
            })
          }
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong when retrieving this groups items. Please try again later',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const addItem = () => {
      selected.value.push({
        action: 'add',
        item_id: null,
        quantity: null,
      })
    }

    const confirmRemove = index => {
      itemAssnIndex.value = index
      confirm.value.open()
    }

    const removeItem = () => {
      const item = selected.value[itemAssnIndex.value]
      if (item.action === 'update') {
        remove.value.push({
          item_id: item.item_id,
          action: 'remove',
        })
      }

      selected.value.splice(itemAssnIndex.value, 1)

      nextTick(() => {
        confirm.value.close()
      })
    }

    const {
      refFormObserver, getValidationState, getValidationStateNonBootstrap,
    } = formValidation()

    const onSubmit = () => {
      saving.value = true

      // One last check to make sure we don't have any overlapping data
      remove.value = remove.value.filter(r => {
        if (selected.value.map(s => s.item_id).includes(r.item_id)) {
          // Change it from 'add' to 'update'
          selected.value.find(s => s.item_id).action = 'update'
        }

        return !selected.value.map(s => s.item_id).includes(r.item_id)
      })

      store.dispatch('app-item/saveItemGroupAssns', {
        id: props.itemGroup.item_group_id,
        data: selected.value.concat(remove.value),
      })
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Successfully saved the items for this group',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
          fetch()
        }).catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong when saving the items for this group. Please try again later',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }).finally(() => {
          saving.value = false
        })
    }

    const filterItems = index => {
      const item = selected.value[index]

      return items.value.filter(i => {
        // Make sure we return the already selected item
        if (+i.item_id === +item.item_id) {
          return true
        }
        // Check to see if the item has already been selected.
        // If it has, remove it.
        return !selected.value.map(s => s.item_id).includes(i.item_id)
      })
    }

    fetch()

    return {
      selected,
      refFormObserver,
      getValidationState,
      getValidationStateNonBootstrap,
      addItem,
      saving,
      items,
      removeItem,
      onSubmit,
      filterItems,
      confirm,
      confirmRemove,
      itemAssnIndex,
    }
  },
  data: () => ({
    required,
    minValue,
  }),
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
